<template>
<div id="page-caratulas-folios-ventas">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Caratulas" class="elevation-1 px-5 py-3">
          <v-card-text>
             <v-form ref="form" lazy-validation>
                <v-container grid-list-md justify-center>
                    <v-row>
                        <v-col sm="3" md="3" lg="3">
                            <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-on="on" v-model="model.fecha" label="Fecha" color="secondary" prepend-icon="event"
                                    @blur="model.fecha = parse_date(model.fecha)" :rules="[rules.required, valida_fecha]">
                                    </v-text-field>
                                </template>

                                <v-date-picker v-model="model.fecha" color="secondary" scrollable locale="es-mx"
                                    @change="searchFolios()"
                                    @input="menu1 = false">
                                    <v-spacer />

                                    <v-btn color="secondary" x-small @click="menu1 = false">
                                    Cancelar
                                    </v-btn>
                                </v-date-picker>
                                </v-menu>
                        </v-col>
                        <v-col sm="3" md="3" lg="3">
                            <v-radio-group dense v-model="model.tipo" row @change="searchFolios()">
                            <v-radio label="Mayoreo" value="mayoreo"></v-radio>
                            <v-radio label="Todos" value="todos"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="6" md="6" lg="6">
                            <v-client-table :data="registros" :columns="columns" :options="options">
                                <template slot="noResults">
                                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                </template>
                            </v-client-table>
                        </v-col>
                    </v-row><br>
                    <v-row>
                        <v-col sm="6" md="6" lg="6">
                            <v-autocomplete v-model="model.tipo_plantilla" :items="tipos_plantilla"
                                :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="_id"
                                label="Seleccione Tipo de Plantilla" :rules="[rules.required]">
                            </v-autocomplete>
                        </v-col>
                    </v-row>


                </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" @click.native="generarWord()">
            <v-icon small>print</v-icon> Imprimir
            </v-btn>
        </v-card-actions>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>
export default {
  components: {
  },
  mounted: function() {
    if (!this.verificaPermiso('t.ventas.folios')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    this.searchFolios();
  },

  created: function() {
    this.get_plantillas();
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha = today;
  },
  data() {
    return {
        menu1:false,
        tipos_plantilla:[],
      model_filters: "",
      fab: [],
      name: "datagrid",
      columns: [
        "caja",
        "folio_inicial",
        "folio_final"
      ],
      show_loading: true,
      options: {
        perPage: 10,
        pagination: {
          show: true
        },
        filterable: ["caja","folio_inicial","folio_final"],
        sortable: ["caja","folio_inicial","folio_final"],
        headings: {
          "caja":"Serie",
          "folio_inicial":"Folio Inicio",
          "folio_final":"Folio Fin"
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Ventas",
          disabled: true,
          href: ""
        },
        {
          text: "Caratulas",
          href: ""
        }
      ],

      modal: false,

      update: false,

      model: {
        tipo:"mayoreo",
        fecha:"",
        tipos_plantilla:"",
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: [],
    };
  },
  methods: {
    valida_fecha: function(fecha) {
        if (fecha == "Invalid date")
            return "Fecha no válida."
        else
            return true;
    },
    parse_date(date) {
        if (!date) return null;
        return window.moment(String(date)).format("YYYY-MM-DD");
    },
    algo:function (keys, values) {
        var folio_min = 0;
        var folio_max = 0;
        if(values.length > 0 && values[0].folio !== undefined){
            folio_min = values[0].folio;
            folio_max = values[0].folio;
        }
            for (var idx = 0; idx < values.length; idx++) {
            if(folio_min > values[idx].folio)
                folio_min = values[idx].folio;
            if(folio_max < values[idx].folio)
                folio_max = values[idx].folio;
            }
            return {"folio_inicial": folio_min, "folio_final":folio_max};

    },

    searchFolios: function(){

        if (this.model.fecha != undefined && this.model.fecha != null && this.model.fecha != "" ) {
           window.dialogLoader.show('Espere un momento por favor..');
           var self = this;

            let data = {
                "selector":{
                    "type":"ventas",
                    "estatus":{"$exists":true},
                    "fecha": {
                        "$gte": this.model.fecha,
                        "$lte": this.model.fecha+ "T23:59:59.999"
                    },
                    "tipo_venta": {"$exists":true}
                },
                "fields":["caja","folio_int","fecha"],
                "use_index":"_design/2ed9df138e95ee22bf4a10547c2d4feab823a47e"
            };

            if(this.model.tipo == "mayoreo"){
                data["selector"]["tipo_venta"] = "mayoreo";
            }

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/';

            axios.post(url, data)
            .then(response => {
                response.data.docs.sort(this.ordenar_caja);
                let respuesta = response.data.docs;

                self.registros = [];
                var folios = {};

                respuesta.forEach(x => {
                    if(folios[x.caja] != undefined){
                        if(folios[x.caja]["folio_inicial"] > x.folio_int)
                            folios[x.caja]["folio_inicial"] = x.folio_int;
                        if(folios[x.caja]["folio_final"] < x.folio_int)
                            folios[x.caja]["folio_final"] = x.folio_int;
                    } else {
                        folios[x.caja] = {"caja":x.caja, "folio_inicial": x.folio_int,"folio_final": x.folio_int};
                    }
                });

                for(var k in folios) {

                    var f = folios[k];
                    self.registros.push({
                        "caja": f.caja,
                        "folio_inicial": f.folio_inicial,
                        "folio_final": f.folio_final,
                    });
                }

            }).catch( error => {
                console.log(error);
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al consultar folios. Intente nuevamente.",
                    footer: ""
                  });
            }).then(()=>{
                window.dialogLoader.hide();
            });
        }


    },

    generarWord: function() {
      if (this.$refs.form.validate() && this.registros.length > 0) {

        window.dialogLoader.show('Espere un momento por favor..');

            let data={
                fecha: this.model.fecha,
                registros: this.registros,
                tipo_plantilla: this.model.tipo_plantilla
            };

            window.axios
                .post(process.env.VUE_APP_REPORTES_URL + "/bodeguita/get_caratulas_folios/", data, {
                      "Content-type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      "Allow": "POST",
                      "cache-control": "no-cache",
                      "responseType": "arraybuffer"
                  })
                .then(response => {
                      var bytes = response.data;
                      let blob = new Blob([bytes], {
                              type: response.headers["content-type"]
                          }),
                          url = window.URL.createObjectURL(blob);

                      var a = document.createElement("a");
                      document.body.appendChild(a);
                      a.style = "display: none";
                      a.href = url;
                      a.download = "Caratulas_"+window.moment().format("DD-MM-YYYY")+".docx";
                      a.click();
                      a.parentNode.removeChild(a);
                })
                .catch(error => {
                  console.log(error);
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al generar el sello. Intente nuevamente.",
                    footer: ""
                  });
                }).then(()=>{
                  window.dialogLoader.hide();
                });

      } else if (this.registros.length <= 0){
        this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "No hay folios que imprimir. Favor de verificar.",
            footer: ""
        });
      }

    },
    get_plantillas: function() {

      let data = {
        "selector": {"type":"plantillas", "estatus":"Activo","tipo_plantilla":"Caratula Folio"},
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0){
            response.data.docs.sort(this.ordenar_nombre);
            this.tipos_plantilla = response.data.docs;
          } else
            this.tipos_plantilla = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las plantillas.",
            footer: ""
          });
        });
    },
    ordenar_nombre: function( a, b ){
        if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
            return -1;
        }
        if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
            return 1;
        }
        return 0;
    },
    ordenar_caja: function( a, b ){
        if ( a.caja.toLowerCase() < b.caja.toLowerCase()){
            return -1;
        }
        if ( a.caja.toLowerCase() > b.caja.toLowerCase()){
            return 1;
        }
        return 0;
    },


  }
};
</script>
